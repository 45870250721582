import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

// Component to handle authenticated user access
const AuthUser = () => {
    // Get user authentication status and loading state from the context
    const { isUser, isLoading } = useContext(AuthContext);

    // Render loading message if authentication data is loading
    if (isLoading) {
        return <div className="spinner"></div>;
    }

    // Render nested routes if user is authenticated, otherwise navigate to login
    return isUser ? <Outlet /> : <Navigate to="/auth/login" />;
};

export default AuthUser;
