import React from 'react';
import './CardClient.css'

const CardClient = ({ client }) => {
    return (
        <div className='card'>
            <div>
                <p>
                {client.name} {client.name_alias !== "" && `- ${client.name_alias}`}
                </p>
                <p>
                    {client.town}
                </p>
            </div>


            <div className='buttonArrow' >
                <p>→</p>
            </div>

        </div>
    );
};

export default CardClient;