import axios from "axios";
import { getToken } from "./localStorageService";

const Axios = axios.create(
    {
        baseURL: 'https://oleoval.miutech.fr/api/index.php/'

    }
)

Axios.interceptors.request.use(request => {
    const token = getToken();
    if (token)
        request.headers["DOLAPIKEY"] = token

    return request
});


export default Axios
