import React, { createContext, useEffect, useState } from 'react';
import { getToken } from '../services/localStorageService';

// ********************************************************
// TODO: Handle if the token is present but expired or fake
// ********************************************************


// Create a context for authentication information
export const AuthContext = createContext()

// Create a provider component for the authentication context
export const LogProvider = ({ children }) => {

    // State for user authentication status
    const [isUser, setIsUser] = useState(false);

    // State to track whether the authentication data is loading
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {

        // Check if a token exists in local storage
        getToken() ?
            // If token exists, initiate authentication
            findToken()
            :
            // If no token, set loading to false
            setIsLoading(false)
    }, [])

    // Function to handle token presence and set authentication
    const findToken = () => {
        setIsUser(true);
        setIsLoading(false);
    }

    // Function to handle token presence and set authentication
    return (
        <AuthContext.Provider value={{ isUser, isLoading, setIsUser }}>
            {children}
        </AuthContext.Provider>
    )
}
