import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'

const Header = () => {
    return (
        <div className='header'>

            <div className='container'>
                <div className='title'>
                    <Link to='/'>
                        <h1>Oleoval</h1>
                    </Link>
                </div>
                <Link to='/liste'>
                    <img src="/list.png" alt="" />
                </Link>

            </div>

        </div>
    );
};

export default Header;

