import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';

const Layout = () => {
    return (
        <div>
            <main className='flex'>
                <Header />
                <div className='content'>
                    <Outlet />
                </div>
            </main>
        </div>
    );

};

export default Layout;